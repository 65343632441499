import $ from 'jquery';
// import Tether from 'tether';
import './globals';
import 'bootstrap';
import 'hideshowpassword';

$(function() {
  $('#password').hideShowPassword({
    show: false,
    innerToggle: 'focus',
    toggle: {
      element: '<i>',
      className: 'toggle-password icon-eye',
      offset: '.5rem'
    }
  });
  $('[data-toggle="tooltip"]').tooltip();
  $('.sidenav-toggle').click(function() {
    $('.main').toggleClass('is-open');
  })
});
